<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <fep-switchmenu :currentviewlankey="currentviewlankey" @switchmenu="switchmenu"/>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlitex3':'vcontentfull'):'vcontentfull'" class="forcenovcnttop">
                <a-row>
                    <a-col v-if="false" :span="24">
                        <div class="packageschosen" :class="ismobile?'pkgviewmobile':null">
                            <div class="choseninfo">
                                {{$langsmgr('langkey.component.packages.chosenurplan')}}
                            </div>
                            <div class="chosencate">
                                <a-segmented v-model:value="segmentsvalue" :options="segmentsdata"/>
                            </div>
                        </div>
                    </a-col>
                    <div :style="{width:'100%',height:'80px'}"></div>
                    <a-skeleton active :loading="loading_pkgs" :style="{padding:'20px'}">
                        <a-col v-if="servpacksdata.length>0x00" v-for="pack in servpacksdata" :span="winwidth>winwidthmodelimit&&!ismobile?8:24">
                            <div class="pkgcontainer">
                                <a-card :style="{borderRadius:'6px',textAlign:'left'}" :title="pack.name" :bordered="false"
                                    :bodyStyle="{margin:0,padding:0}" 
                                    :headStyle="{textAlign:'center',fontSize:'20px',fontWeight:'bold',color:'#5e72e4',lineHeight:'100px'}"
                                    >
                                    <div class="pkgcatesprice" :class="ismobile?'pkgviewmobile':null">
                                        <div :class="pack.opts.length>0x01?'pkgprice-multiple':'pkgprice-single'">
                                            <div :class="(pack.opts.length>0x01?'pkgprice-multiple':'pkgprice-single')">
                                                <span v-if="pack.hascoupon" class="pkgprice-final">￥{{pack.finalprice}}</span>
                                                <span :class="pack.hascoupon?'pkgprice-linethough':null">￥{{pack.showprice}}</span>
                                            </div>
                                            <div v-if="pack.opts.length>=0x01" class="showpricename"><span>({{pack.showpricename}})</span></div>
                                        </div>
                                        <div v-if="pack.opts.length>0x01" class="pkgcates">
                                            <a-radio-group option-type="button" :id="pack.id" button-style="solid" 
                                                v-model:value="pack.showsign" :options="pack.opts"
                                                @click.stop="" @change="planchange">
                                            </a-radio-group>
                                        </div>
                                    </div>
                                    <div class="pkgintro" v-html="pack.description"></div>
                                    <a-divider/>
                                    <div class="pkgpurchase" :class="ismobile?'pkgviewmobile':null">
                                        <a-button @click.stop="" type="primary" size="large" @click="purchase(pack)">
                                            <ShoppingCartOutlined/>
                                            {{$langsmgr('langkey.component.packages.checkout')}}
                                        </a-button>
                                    </div>
                                </a-card>
                            </div>
                        </a-col>
                        <a-col v-else :span="24">
                            <div class="emptydata">
                                <a-empty :image="EmptyIcon"/>
                            </div>
                        </a-col>
                    </a-skeleton>
                </a-row>
                <Cntfooter/>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,ShoppingCartOutlined} from '@ant-design/icons-vue'
import { Empty } from 'ant-design-vue';
import { ref,reactive,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const EmptyIcon = Empty.PRESENTED_IMAGE_SIMPLE

const loading_pkgs = ref(true)
setTimeout(()=>{loading_pkgs.value=false},1000)

const currentviewlankey = ref('langkey.menuitem.packages')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())
const segmentsdata = reactive([
    gconfig.$langsmgr('langkey.component.packages.all'),
    gconfig.$langsmgr('langkey.component.packages.bycircle'),
    gconfig.$langsmgr('langkey.component.packages.bydisposable'), 
    ])
const segmentsvalue = ref(segmentsdata[0x00])

const segmentpkgcates = reactive([])
const segmentpkgvalue = ref(segmentpkgcates[0x00])

const tag_vpkg = ref({
    "tag": ref('30'),
    "tas": ref('180')
})

const servpacksdata = ref([])


onMounted(() => {
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    loadservpacks()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const purchase = (pack) => {
    //console.log(pack)
    var __rechargeuri = `#/plan?p=${pack.id}${(pack.selectedplanid&&pack.selectedplanid!=''?`&s=${pack.selectedplanid}`:'')}`
    //console.log(__rechargeuri)
    location.href = __rechargeuri
}

const planchange = (e) => {
    var __planid = e.target.value
    var __packid = $(e.nativeEvent.srcElement.parentElement.parentElement.parentElement).attr("id")
    for(var i = 0x00; i < servpacksdata.value.length;i++){
        var __servpack = servpacksdata.value[i]
        if(__servpack.id == __packid) {
            for(var p = 0x00; p < __servpack.opts.length; p++){
                var __plan = __servpack.opts[p]
                if(__plan.value == __planid){
                    __servpack.selectedplanid = __plan.pid
                    __servpack.showprice = __plan.price
                    __servpack.hascoupon = __plan.hascoupon
                    __servpack.finalprice = __plan.finalprice
                    break
                }
            }
            break
        }
    }
}

const segmentedchanged = e => {
    //console.log(e)
}

const test = ()=>{
    //console.log('test')
}

const loadservpacks = () => {
    loading_pkgs.value = true

    axios.get(`${gconfig.$backendbase}/setmeals/v3/avaiableservpacks`,{
        params:{r:Math.random()},
        headers:gconfig.$getauthheaders()
    }).then(resp=>{
        servpacksdata.value.splice(0x00, servpacksdata.value.length)
        if(resp.data.data.result){
            for(var i = 0x00; i < resp.data.data.servpacks.length; i++) {
                var __servpack = resp.data.data.servpacks[i]
                var __localspack = {
                    id: __servpack.packid,
                    name: decodeURIComponent(__servpack.displayname),
                    description: decodeURIComponent(__servpack.remark),
                    hascoupon: __servpack.hascoupon,
                    showsign: ref(''),
                    showprice: ref(0),
                    finalprice: ref(0),
                    showpricename: ref(''),
                    selectedplanid: '',
                    opts: []
                }
                if(__servpack.plans.length>0x01){
                    for(var s = 0x00; s < __servpack.plans.length; s++){
                        var __plan = __servpack.plans[s]
                        if(__plan.isdefault) {
                            __localspack.opts.push({
                                pid: __plan.id,
                                label: decodeURIComponent(__plan.display),
                                value: __plan.sign,
                                price: __plan.price.toFixed(0x02),
                                hascoupon: __plan.hascoupon,
                                finalprice: __plan.finalprice.toFixed(0x02),
                                duration: __plan.durationlimit
                            })
                            __localspack.showsign.value = __plan.sign
                            __localspack.showprice.value = __plan.price.toFixed(0x02)
                            __localspack.finalprice.value = __plan.finalprice.toFixed(0x02)
                            __localspack.showpricename.value = decodeURIComponent(__plan.display)
                            __localspack.selectedplanid = __plan.id
                        } else continue
                    }
                    if(!__localspack.showsign.value||__localspack.showsign.value==''||
                        !__localspack.showprice.value||__localspack.showprice.value==0||
                        !__localspack.selectedplanid||__localspack.selectedplanid==''){
                            __localspack.showsign.value = __localspack.opts[0x00].value
                            __localspack.showprice.value = __localspack.opts[0x00].price
                            __localspack.finalprice.value = __localspack.opts[0x00].finalprice
                            __localspack.selectedplanid = __localspack.opts[0x00].pid
                        }
                }else if(__servpack.plans.length<=0x01){
                    __localspack.showsign.value = ''
                    __localspack.showprice.value = __servpack.price.toFixed(0x02)
                    __localspack.finalprice.value = __servpack.finalprice.toFixed(0x02)
                    __localspack.selectedplanid = ''
                }
                servpacksdata.value.push(__localspack)
            }
        }
        loading_pkgs.value=false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

</script>

<script>

// export default {
//     methods: {
//         planchange(e){

//         }
//     }
// }

</script>

<style src="./../assets/common.css" scoped></style>

<style scoped>
.forcenovcnttop{
    margin-top: 0;
}
.packageschosen{
    padding:15px;
}
.choseninfo{
    font-size: 30px;
    color:#fff;
}
.chosencate{
    margin-top:20px;
}
.emptydata{
    padding:50px 0;
}
:deep(.ant-segmented-item) {
    color:#0665d0;
}
:deep(.ant-segmented-item-selected) {
    background-color:#0665d0;
    color:white;
} 
/* :deep(.ant-segmented) {
    background-color:transparent;
    border:solid 1px #0665d0;
}
*/
.pkgcontainer{
    margin: 20px 15px 10px 15px;
}
.pkgcatesprice{
    padding:20px 20px 0px 20px;
}
.pkgviewmobile{
    text-align: right;
}
.pkgprice-multiple{
    font-size: 48px;
    font-weight: bold;
    color:#525f7f;
    text-align: center;
}
.pkgprice-linethough{
    font-size:24px!important;
    color:#888;
    text-decoration-line: line-through;
}
.pkgprice-final{
    margin-right: 15px;
}
.pkgprice-single{
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color:#525f7f;
    line-height:93px;
}
.pkgcates{
    padding-top:5px;
    font-size: 14px;
    color:#525f7f;
    text-align: center;
}
.pkgintro{
    padding:0px 25px;
    font-size:16px;
}
.pkgpropsinfo{
    font-weight: bold;
    color:#5e692d;
    background-color:#e6f0db;
    border-radius: 4px;
}
.pkgdetail{
    padding:10px 0;
}
.pkgdetail p{
    margin:0;
    padding:3px 0;
    line-height:25px;
    color:#666666;
}
.pkgpurchase{
    padding:5px 20px 40px 20px;
    text-align: center;
}
.showpricename{
    font-size:16px;
    line-height:20px;
}

</style>