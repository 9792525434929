<template>
    <div class="loginbg">
        <div>
            <div class="loginpadbghead"></div>
            <div class="loginpadbg"></div>
            <div class="logintitle">
                <div class="logintitle-sitename">{{ $langsmgr("langkey.sitename") }}</div>
                <div class="logintitle-sitenameshort">{{ $langsmgr("langkey.sitesubtitle") }}</div>
            </div>
        </div>
        <div class="loginpad">
            <div class="loginform" :class="ismobile?'widthifmobile':'widthifpc'">
                <div class="padtitle noselect">{{ $langsmgr("langkey.component.repasswd.submit.text") }}</div>
                <a-divider></a-divider>
                <div class="loginarea">
                    <div class="loginarea-line pt10px">
                        <a-input ref="uemailinput" v-model:value="relval_useremail" size="large" :disabled="isnotcheck" :placeholder="$langsmgr('langkey.component.login.uname.placeholder')">
                            <template #prefix>
                                <MailOutlined/>
                            </template>
                        </a-input>
                    </div>
                    <div class="loginarea-line pt20px">
                        <a-input-search v-model:value="relval_verifycode" size="large" :disabled="isnotcheck" :loading="loading_sendverifymail"
                            :placeholder="$langsmgr('langkey.component.repasswd.vcode.holder')" :enter-button="$langsmgr('langkey.component.repasswd.vcode.buttontext')" @search="sendverifymail"/>
                    </div>
                    <div class="pt20px"></div>
                    <a-divider :style="{margin:'0 0 10px 0'}"/>
                    <div class="loginarea-line pt20px">
                        <a-input-password v-model:value="relval_newpasswd" size="large" :disabled="isnotcheck && !isvcodechecked" :placeholder="$langsmgr('langkey.component.login.passwd.placeholder')">
                            <template #prefix>
                                <edit-outlined/>
                            </template>
                        </a-input-password>
                    </div>
                    <div class="loginarea-line pt20px">
                        <a-input-password v-model:value="relval_verifypasswd" size="large" :disabled="isnotcheck && !isvcodechecked" :placeholder="$langsmgr('langkey.component.repasswd.renewpasswd.holder')">
                            <template #prefix>
                                <edit-outlined/>
                            </template>
                        </a-input-password>
                    </div>
                    <!-- <div class="loginarea-line">
                        <span class="recaptchacheck">
                            <CheckOutlined v-if="!isnotcheck" class="checkoutlined"/>
                            <a-button :type="recaptchatype" size="small" :loading="isrecaptchachecking" :disabled="!isnotcheck" @click="recaptcha">{{recaptchatext}}</a-button>
                        </span>
                    </div> -->
                    <div class="loginarea-line pt20px">
                        <a-button type="primary" :block="false" size="large" :loading="loading_repasswd" :disabled="isnotcheck" 
                            @click="repasswd">
                            <ReloadOutlined/>
                            {{logintext}}
                        </a-button>
                    </div>
                </div>
                <CPRArea type="repasswd"/>
            </div>
        </div>
    </div>
</template>
<script setup>
import { LoadingOutlined,UserOutlined,EditOutlined,CheckOutlined,MailOutlined,ReloadOutlined } from '@ant-design/icons-vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { getCurrentInstance, ref, nextTick } from 'vue'
import axios from 'axios'
import { notification,message } from 'ant-design-vue'
import CPRArea from './cprarea.vue'

const unameinput = ref(null)
const uemailinput = ref(null)

const username = ref("")
const state = ref(false)
const isnotcheck = ref(false)
const isvcodechecked = ref(false)
const isloginning = ref(false)
const isrecaptchachecking = ref(false)
const recaptchatype = ref('primary')
const logintext = ref(null)
const recaptchatext = ref(null)

const relval_useremail = ref('')
const relval_newpasswd = ref('')
const relval_verifypasswd = ref('')
const relval_verifycode = ref('')

const loading_sendverifymail = ref(false)
const loading_repasswd = ref(false)
        
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha()
const __instance = getCurrentInstance()
const gconfig = __instance.appContext.config.globalProperties
const ismobile = ref(__instance.appContext.config.globalProperties.$ismobile())

logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.repasswd.submit.text")
recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.normal")



const recaptcha = async() => {
    isrecaptchachecking.value = true;
    recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.working")
    var token = null;
    try{
        await recaptchaLoaded()
        token = await executeRecaptcha('login')
        //console.log(token);
    } catch(e) {
        //console.log("async error.")
    }
    isrecaptchachecking.value = false;
    if(token) {
        recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.success")
        logintext.value = gconfig.$langsmgr('langkey.component.repasswd.submit.text')
        recaptchatype.value = "text"
        isnotcheck.value = false
        nextTick(()=>{
            uemailinput.value.focus();
        })
    } else {
        recaptchatext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.recaptcha.failed")
    }
}

const register = ()=>{
    location.href="#/register"
}
const repassword = ()=>{
    isloginning.value = true;
    logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.repasswd.submit.text")
    // axios.get("https://google.com")
    //     .then((response)=>{
    //         console.log(response);
    //     })
    //     .catch((error)=>{
    //         console.log(error);
    //     })
    if(false)
        setTimeout(()=>{location.href="#/dashboard"},1500)
    else {
        setTimeout(()=>{
            notification['error']({
                message: __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.result.failed.message"),
                description: __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.result.failed.unknowusernameorpassworderror"),
                duration: 3
            })
            isloginning.value = false;
            logintext.value = __instance.appContext.config.globalProperties.$langsmgr("langkey.component.login.submit.normal")
        },1500)
    }
}

const repasswd = ()=>{
    if(!relval_useremail.value||relval_useremail.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.repasswd.valitemsg.mailempty'))
        return
    }
    if(!relval_verifycode.value||relval_verifycode.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.repasswd.valitemsg.vcodeempty'))
        return
    }
    if(!relval_newpasswd.value||relval_newpasswd.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.repasswd.valitemsg.newpasswdempty'))
        return
    }
    if(relval_newpasswd.value!=relval_verifypasswd.value){
        message.error(gconfig.$langsmgr('langkey.component.repasswd.valitemsg.twicepasswdnotmatch'))
        return
    }

    loading_repasswd.value = true
    axios.post(`${gconfig.$backendbase}/usrctl/v3/forgotchangepasswd`,{
        mailaddress: relval_useremail.value,
        code: relval_verifycode.value,
        newpasswd: relval_newpasswd.value
    },{
        params: {r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data){
            notification['success']({
                message: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.success'),
                description: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.success.desc'),
                duration: 3
            })
        }else{
            notification['error']({
                message: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.fail'),
                description: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.fail.unknowemail'),
                duration: 3
            })
        }
        loading_repasswd.value=false
    }).catch(err=>{
        notification['error']({
            message: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.fail'),
            description: gconfig.$langsmgr('langkey.component.repasswd.resetmsg.fail.unknowerror'),
            duration: 3
        })
    })
}

const sendverifymail = ()=>{
    if(!relval_useremail.value||relval_useremail.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.repasswd.valitemsg.mailempty'))
        return
    }
    loading_sendverifymail.value = true
    axios.get(`${gconfig.$backendbase}/usrctl/v3/sendforgotmail`,{
        params:{
            mailaddress: relval_useremail.value,
            r:Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data){
            message.success(gconfig.$langsmgr('langkey.component.repasswd.optmsg.sended'))
        }else{
            message.error(gconfig.$langsmgr('langkey.component.repasswd.optmsg.unsend'))
        }
        loading_sendverifymail.value=false
    }).catch(err=>{
            message.success(gconfig.$langsmgr('langkey.component.repasswd.optmsg.unsend'))
    })
}
</script>

<style src="./../assets/common.css" scoped></style>

<style scoped>
.fixedline{
    padding:10px 0 0 0;
    margin:0;
}
</style>
