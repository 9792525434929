import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/reset.css'
import './assets/common.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import sidemenus from './components/sidemenus.vue'
import headmenus from './components/headmenus.vue'
import switchmenu from './components/switchmenu.vue'
import prolib from './components/prolib.js'
import langsmgr from './components/langsmanager.js'
import fstorage from './components/fstorage.ts'

const app = createApp(App)

//#region system global options
const $ = require('jquery');
window.$ = $;

app.config.globalProperties.$storage = fstorage

//#endregion

//#region components registion
app.use(fstorage)
app.use(Antd)
app.use(prolib)
app.use(langsmgr)
app.use(VueReCaptcha, { 
    siteKey: app.config.globalProperties.$recaptchav3sitekey, 
    loaderOptions:{
        autoHideBadge:true
    }
 })

app.component('fep-sidemenus', sidemenus)
app.component('fep-headmenus', headmenus)
app.component('fep-switchmenu', switchmenu)
//#endregion


app.mount('#app')