<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <fep-switchmenu :currentviewlankey="currentviewlankey" @switchmenu="switchmenu"/>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlitex2':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card :title="$langsmgr('langkey.component.dashboard.mysubscribe')" :bordered="false" class="rowcard" :size="ismobile?'small':'default'"
                            :style="{borderRadius:'6px'}" :headStyle="{backgroundColor:'#f8f9fc'}">
                            <a-skeleton active :loading="loading_subscribe">
                                <div v-if="usersubscribed">
                                    <p>
                                        <h2><strong>{{userservpack.packname}}</strong></h2>
                                    </p>
                                    <p class="expiresinfo">
                                        <span>{{$langsmgr('langkey.component.dashboard.subscribe.expiresinfo',{"{0}":userservpack.expiresed,"{1}":userservpack.expiresdays})}}</span>
                                        <span v-if="userservpack.expiresed>0x1e">{{$langsmgr('langkey.component.dashboard.subscribe.resetinfo',{"{0}":userservpack.resetdays})}}</span>
                                    </p>
                                    <p class="progressline">
                                        <a-progress :percent="userflowpercent" status="active" :show-info="false" size="small"></a-progress>
                                        <strong>{{$langsmgr('langkey.component.dashboard.subscribe.flowinfo',{"{0}":userservpack.flowused,"{1}":userservpack.flowtotal})}}</strong>
                                    </p>
                                </div>
                                <div v-else>
                                    <a class="empsubscr" href="javascript:;" @click="buypackage">
                                        <div class="addsubscr"><strong><PlusOutlined/></strong></div>
                                        <div class="buysubscr">{{$langsmgr('langkey.component.dashboard.buysubscribe')}}</div>
                                    </a>
                                </div>
                            </a-skeleton>
                        </a-card>

                        <a-card :bordered="false" class="rowcard" :bodyStyle="{padding:0}" :style="{borderRadius:'6px',marginTop:'0'}">
                            <div slot="title">
                                <div>
                                    <div class="servtabhead">
                                        <a-row>
                                            <a-col span="12"><span class="tabcellleft">{{$langsmgr('langkey.component.services.tablecolumn.name')}}</span></a-col>
                                            <a-col span="5" :style="{textAlign:'center'}">
                                                <a-tooltip placement="top">
                                                    <template #title><span>{{$langsmgr('langkey.component.services.tooltips.status')}}</span></template>
                                                    <span>{{$langsmgr('langkey.component.services.tablecolumn.status')}}</span>
                                                    <QuestionCircleOutlined/>
                                                </a-tooltip>
                                            </a-col>
                                            <a-col span="4">
                                                <a-tooltip placement="top">
                                                    <template #title><span>{{$langsmgr('langkey.component.services.tooltips.rate')}}</span></template>
                                                    <span>{{$langsmgr('langkey.component.services.tablecolumn.rate')}}</span>
                                                    <QuestionCircleOutlined/>
                                                </a-tooltip>
                                            </a-col>
                                            <a-col span="3"><span class="tabcellleft markup">{{$langsmgr('langkey.component.services.tablecolumn.tags')}}</span></a-col>
                                        </a-row>
                                    </div>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                            </div>
                            <a-skeleton active :loading="loading_nodes" :style="{padding:'20px'}">
                                <div v-if="userservnodes.length>0x00" v-for="node in userservnodes">
                                    <div class="servtabrow">
                                        <a-row>
                                            <a-col span="12"><span class="tabcellleft">{{node.name}}</span></a-col>
                                            <a-col span="5" :style="{textAlign:'center'}"><span><a-badge :color="node.stacolor"/></span></a-col>
                                            <a-col span="4"><span><a-tag class="ratiotag">{{node.ratio}} x</a-tag></span></a-col>
                                            <a-col span="3"><span class="tabcellleft markup">{{node.tag}}</span></a-col>
                                        </a-row>
                                    </div>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                                <a-empty v-else :image="emptyImage"/>
                            </a-skeleton>
                            <div class="tabfooter">
                                <span class="caretright"><CaretRightOutlined/></span>
                                <span class="caretleft"><CaretLeftOutlined/></span>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>

            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CloseOutlined,PlusOutlined,QuestionCircleOutlined,CaretLeftOutlined,CaretRightOutlined} from '@ant-design/icons-vue'
import {Empty} from 'ant-design-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'

const loading_nodes = ref(true)
const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE

const currentviewlankey = ref('langkey.menuitem.services')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(gconfig.$uimenucollapsed)
const ismobile = ref(gconfig.$ismobile())
const documentshowstate = ref(false)
const usernosubscribe = ref(false)
const usersubscribed = ref(false)
const userservpack = ref({
    subscribeid: null,
    subscribeuri: null,
    packid: null,
    planid: null,
    packname: null,
    expiresed: null,
    expiresdays: null,
    resetdays: null,
    flowused: null,
    flowtotal: null,
    balance: null,
    popbalance: null,
    orderopened: null,
    orderclosed: null
})
const userflowpercent = ref(0)
const userservnodes = ref([])
const loading_subscribe = ref(true)

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    axios.get(`${gconfig.$backendbase}/nodes/getservicenodes`,{
        params: { r: Math.random() },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        usernosubscribe.value = !(response.data.data.haspack)
        if(response.data.data.result){
            userservnodes.value.splice(0x00,usernosubscribe.value.length)
            for (var i = 0x00; i < response.data.data.ucgroups.length; i++) {
                var __group = response.data.data.ucgroups[i];
                for (var n = 0x00; n < __group.ucnodes.length; n++) {
                    var __node = __group.ucnodes[n]
                    userservnodes.value.push({
                        name: decodeURIComponent(__node.displayname),
                        ratio: __node.multiple.toFixed(0x02),
                        state: __node.state,
                        stacolor: __node.state == 0x00 ? 'gray' : 
                            __node.state == 0x01 ? 'green' : 'red',
                        tag: decodeURIComponent(__node.remark)
                    })
                }
            }
        }
        loading_nodes.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })

    axios.get(`${gconfig.$backendbase}/dashboard/v3/useroverview`, {
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response.data.data.result) {
            usersubscribed.value = response.data.data.servpack != null
            if(usersubscribed.value) {
                gconfig.$vusubscrid = userservpack.value.subscribeid = response.data.data.subscribeid

                userservpack.value.subscribeuri = response.data.data.subscribeuri
                userservpack.value.packid = response.data.data.servpackid
                userservpack.value.packname = decodeURIComponent(response.data.data.servpack)
                userservpack.value.expiresed = response.data.data.expiresdate
                userservpack.value.expiresdays = response.data.data.expiresdays
                userservpack.value.resetdays = response.data.data.resetdays
                userservpack.value.flowused = response.data.data.flowused.toFixed(0x02)
                userservpack.value.flowtotal = response.data.data.flowtotal.toFixed(0x02)
                userservpack.value.balance = response.data.data.balance.toFixed(0x02)
                userservpack.value.popbalance = response.data.data.popbalance.toFixed(0x02)
                userservpack.value.orderopened = response.data.data.orderopened
                userservpack.value.orderclosed = response.data.data.orderclosed

                userflowpercent.value = (userservpack.value.flowused / userservpack.value.flowtotal)*100
                //console.log(userflowpercent.value)
            }
        }
        loading_subscribe.value = false
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) gconfig.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const buypackage = () => {
    gconfig.$uimenuselectedkey = "10"
    location.href="#/plans"
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.nosubscribe {
    display:block;
    text-align: center;
    margin:30px auto;
    color:#999999;
}
.nosubscribe:hover{
    color:#666666;
}
.plusicon{
    font-size:35px;
    font-weight:bolder;
}
.servtabhead{
    padding: 15px 20px;
}
.servtabhead span{
    padding-right:5px;
}
.servtabrow {
    padding: 15px 20px;
}
.servtabrow:hover{
    background-color:#e6f6ff;
}
.tabcellleft{
    float: left;
}
.markup{
    padding-left:10px;
}
.ratiotag{
    background-color:#f0f0f0;
    color:#666666;
    width:60px;
    text-align: center;
}
.tabfooter{
    background-color:#f1f1f1;
    color:#a3a3a3;
    text-align: left;
    height:16px;
    line-height:16px;
    font-size: 10px;
}
.caretletf{
    float:left;
    padding-left:5px;
}
.caretright{
    float:right;
}
.empsubscr{
    display: block;
    text-align:center;
    color:#999999;
}
.empsubscr:hover{
    color:#666666;
}
.addsubscr{
    font-size:40px;
    font-weight: bolder;
}
</style>