<template>
    <div>
        <div v-if="winwidth>winwidthmodelimit&&!ismobile">
            <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
            <MenuFoldOutlined v-if="collapsed" class="trigger" @click="switchmenu"/>
            <MenuFoldOutlined v-else class="trigger" @click="switchmenu"/>
            <span class="noselect currentview">{{$langsmgr(currentviewlankey)}}</span>
        </div>
        <div v-else>
            <MenuUnfoldOutlined class="trigger" @click="switchmenu"/>
            <span class="noselect currentview">{{$langsmgr(currentviewlankey)}}</span>
        </div>
    </div>
</template>

<script setup>
import {ref,defineEmits,getCurrentInstance, onMounted} from 'vue'
import {MenuFoldOutlined,MenuUnfoldOutlined} from '@ant-design/icons-vue'

defineProps(['currentviewlankey'])

const winwidthmodelimit = 1320
const winwidth = ref(0)

const ginstance = getCurrentInstance()
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)

const switchmenu = () =>{
    collapsed.value = !collapsed.value
    emits('switchmenu')
    console.log(collapsed.value)
}
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)
})

const emits = defineEmits(['switchmenu'])
</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>

</style>