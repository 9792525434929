<template>
    <div class="copyright">
        <span class="fleft pl10px">
            <a-button v-if="type=='signin'" type="link" size="small" @click="register" class="normaltext" :style="{color:'#999999'}">{{ $langsmgr("langkey.component.login.signup") }}</a-button>
            <a-divider v-if="type=='signin'" type="vertical" :style="{backgroundColor:'#cccccc',height:'14px',margin:'0 10px'}"/>
            <a-button v-if="type=='signin'" type="link" size="small" @click="repasswd" class="normaltext" :style="{color:'#999999'}">{{ $langsmgr("langkey.component.login.repasswd") }}</a-button>
            <a-button v-if="type=='reg'||type=='repasswd'" type="link" size="small" @click="gosignup()" class="normaltext" :style="{color:'#999999'}">{{ $langsmgr("langkey.component.login.returntosignin") }}</a-button>
        </span>
        <span class="fright pr10px lh20px">
            <Translate :outuse="true"/>
        </span>
    </div>
</template>

<script setup>
import {ref,defineProps} from 'vue'
import Translate from './translate.vue'

const props = defineProps(['type'])
const type = ref([props.type?props.type:'signin']);

const repasswd = ()=>{
    location.href="#/repasswd"
}

const gosignup = () =>{
    location.href="#/login"
}

const register = ()=>{
    location.href="#/register"
}
</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>

</style>